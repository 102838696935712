import { Component, Input, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';
import { InputViewModeEnum } from '../bs-ng-dropdown/InputViewModeEnum';

@Component({
  selector: 'bs-ng-textarea',
  templateUrl: './bs-ng-textarea.component.html',
  providers: [MakeProvider(BsNgTextareaComponent)],
})
export class BsNgTextareaComponent extends BsNgInputBaseComponent {
  @Input() cols: number;
  @Input() rows: number;
  @Input() maxLength: string;

  @Input()
  trimWhitespace: boolean = false;

  @Input()
  viewMode: InputViewModeEnum = InputViewModeEnum.Input;

  @Input()
  displayDefaultValue: any = null;

  public get isDisplay(): boolean {
    return this.viewMode == InputViewModeEnum.Display;
  }

  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  override onBlur() {
    if (this.trimWhitespace && this.value?.trim) {
      this.value = this.value.trim();
    }

    super.onBlur();
  }

  get _maxLength(): number {
    return Number(this.maxLength);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }
}
