import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../../base/BaseComponent';
import { MapLocation } from '../../../../../core/models/shared/MapLocation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bs-map-display',
  templateUrl: './bs-map-display.component.html',
})
export class BsMapDisplayComponent extends BaseComponent {
  @Input() key = '';
  @Input() labelText = '';

  _location: MapLocation;
  zoom: number;
  radius: number;
  circleColor: string;

  @Input()
  set location(v: MapLocation) {
    if (v && (isNaN(v.latitude) || isNaN(v.longitude))) {
      throw `not a valid values for latitude: ${v.latitude} or longitude: ${v.longitude}`;
    }

    if (v) {
      this._location = {
        latitude: Number(v.latitude),
        longitude: Number(v.longitude),
        address: v.address,
      };
    }
  }

  constructor(protected translate: TranslateService) {
    super(translate);
    this._location = new MapLocation();
    this.zoom = environment.googleMapConfigs.defaultZoom;
    this.radius = environment.googleMapConfigs.defaultRadius;
    this.circleColor = environment.googleMapConfigs.defaultCircleColor;
  }
}
