<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #display>
  <app-bs-display
    [key]="key"
    [valueText]="
      multiple
        ? resolveMultipleValue(value)
        : value && value[bindLabel]
        ? value[bindLabel]
        : displayDefaultValue
    "
  >
  </app-bs-display>
</ng-template>

<ng-template #inputDropdown>
  <ng-select
    [id]="identifier"
    [name]="name"
    [ngClass]="
      hasRequiredError() ||
      hasForbidden() ||
      hasEqualValidationError() ||
      hasValueFromItemsOnlyError ||
      hasMaxLengthError ||
      hasNotInError ||
      hasNotFoundError
        ? 'is-invalid'
        : touched
        ? 'is-valid'
        : ''
    "
    [(ngModel)]="value"
    [placeholder]="placeholder"
    #control="ngModel"
    [required]="required == 'true' || required == '' ? '' : undefined"
    (blur)="onTouched()"
    [readonly]="readonly"
    [disabled]="disabled"
    [items]="
      sortProp
        ? (items
          | sortArray : (isArabic ? sortProp.ar : sortProp.en) : sortProp.dir)
        : items
    "
    dropdownPosition="bottom"
    appendTo="body"
    [searchable]="true"
    [labelForId]="dropdownLabel"
    [bindLabel]="bindLabel"
    [addTag]="addTag"
    [addTagText]="addTagText"
    (open)="open()"
    (search)="searchFunction($event)"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
  >
  </ng-select>
</ng-template>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      *ngIf="!isDisplay"
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="dropdownLabel"
      >{{ labelText }}

      <a
        *ngIf="infoDialogId"
        class="gold cursor-pointer"
        data-toggle="modal"
        [attr.data-target]="'#' + infoDialogId"
        [attr.title]="infoMessage"
      >
        <i class="fi-info"></i> </a
    ></label>
    <ng-container
      *ngTemplateOutlet="isDisplay ? display : inputDropdown"
    ></ng-container>
    <small
      [id]="identifier + 'Help'"
      *ngIf="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}</small
    >

    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasForbidden()">
      {{ forbiddenMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMaxLengthError">
      {{ maxLengthErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasEqualValidationError()">
      {{ equalValidationMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasValueFromItemsOnlyError">
      {{ valueFromItemsOnlyMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasNotInError">
      {{ notInErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasNotFoundError">
      {{ notFoundMessage }}
    </div>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
    >
      {{ labelText }}

      <a
        *ngIf="infoDialogId"
        class="gold cursor-pointer"
        data-toggle="modal"
        [attr.data-target]="'#' + infoDialogId"
        [attr.title]="infoMessage"
      >
        <i class="fi-info"></i>
      </a>
    </label>

    <div class="col-sm-9">
      <ng-container
        *ngTemplateOutlet="isDisplay ? display : inputDropdown"
      ></ng-container>
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >

      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasForbidden()">
        {{ forbiddenMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMaxLengthError">
        {{ maxLengthErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasEqualValidationError()">
        {{ equalValidationMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasValueFromItemsOnlyError">
        {{ valueFromItemsOnlyMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasNotInError">
        {{ notInErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasNotFoundError">
        {{ notFoundMessage }}
      </div>
    </div>
  </div>
</ng-template>
