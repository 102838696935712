import { InputViewModeEnum } from '../input-components/components/bs-ng-components/bs-ng-dropdown/InputViewModeEnum';

export class FieldOptions {
  public isVisible?: boolean;
  public isRequired?: boolean;
  public isDisabled?: boolean;
  public isReadonly?: boolean;
  public isDisabledToggleOnly?: boolean;
  public isReset?: boolean;
  public markAsTouched?: boolean;
  public value?: any;
  public helpTextKey?: string;
  public helpTextArgs?: string;
  public viewMode?: InputViewModeEnum;

  constructor(options: {
    isVisible?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;

    /**
     * When true, isDisabled will be ignored when options.isDisabled == control.disabled.
     * one use case, when keep switching disabled property, it causes the validate to run, which overrides the setErrors by validators
     * set this property to true if you need only to toggle disabled only if it is not equal to the current disabled value of the control
     */
    isDisabledToggleOnly?: boolean;

    isReset?: boolean;
    markAsTouched?: boolean;
    value?: any;
    helpTextKey?: string;
    helpTextArgs?: string;
    viewMode?: InputViewModeEnum;
  }) {
    this.isVisible = options.isVisible;
    this.isRequired = options.isRequired;
    this.isDisabled = options.isDisabled;
    this.isDisabledToggleOnly = options.isDisabledToggleOnly;
    this.isReset = options.isReset;
    this.markAsTouched = options.markAsTouched;
    this.value = options.value;
    this.helpTextKey = options.helpTextKey;
    this.helpTextArgs = options.helpTextArgs;
    this.viewMode = options.viewMode;
  }
}

export class DisplayFieldOptions extends FieldOptions {
  public url?: string;

  constructor(options: {
    isVisible?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    reset?: boolean;
    markAsTouched?: boolean;
    value?: any;
    url?: string;
  }) {
    super(options);

    this.url = options.url;
  }
}

export class MapFieldOptions extends FieldOptions {
  public markerDraggable?: boolean;

  constructor(options: {
    isVisible?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    reset?: boolean;
    markAsTouched?: boolean;
    value?: any;
    markerDraggable?: boolean;
  }) {
    super(options);

    this.markerDraggable = options.markerDraggable;
  }
}
