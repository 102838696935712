import { MapsAPILoader } from '@agm/core';
import {} from '@angular/google-maps';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { MapLocation } from '../../../../../core/models/shared/MapLocation';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';

@Component({
  selector: 'bs-ng-map',
  templateUrl: './bs-ng-map.component.html',
  providers: [MakeProvider(BsNgMapComponent)],
})
export class BsNgMapComponent
  extends BsNgInputBaseComponent
  implements AfterViewInit
{
  @Input()
  public markerDraggable = true;

  @ViewChild('search')
  searchElementRef: ElementRef;

  @Input()
  showValueDisplay = true;

  @Input()
  notSelectedLocationText = 'Not Selected';

  ///represent what the map is showing, rather than the value
  public address: string;
  public latitude: number;
  public longitude: number;

  public zoom: number;
  public radius: number;
  public circleColor: string;
  private geoCoder: any;

  public currentLatitude: number;
  public currentLongitude: number;

  @Input()
  set loadUserLocation(isLoadUserLocation: boolean) {
    if (isLoadUserLocation) {
      this.setMapToCurrentLocation();
    }
  }

  get value() {
    return super.value;
  }

  set value(v: any) {
    const defaultLocation =
      this.getLoadedCurrentLocation() || this.createDefaultLocation();

    if (v) {
      this.address = v.address;

      ///there is a value entered but not valid location numbers
      if (
        v.latitude == null ||
        v.longitude == null ||
        isNaN(v.latitude) ||
        isNaN(v.longitude)
      ) {
        console.warn(`not a valid values for location`, v);
        this.latitude = defaultLocation.latitude;
        this.longitude = defaultLocation.longitude;
      } else {
        this.latitude = Number(v.latitude);
        this.longitude = Number(v.longitude);
      }
    } else {
      console.warn(
        `${v} is not a valid value for the map, default location used`
      );

      this.address = defaultLocation.address;
      this.longitude = defaultLocation.longitude;
      this.latitude = defaultLocation.latitude;
    }

    super.value = v;
  }

  writeValue(value: any) {
    this.value = value;
  }

  public get gestureHandling(): 'cooperative' | 'greedy' | 'none' | 'auto' {
    if (this.ngControl.disabled) {
      return 'none';
    }

    return 'auto'; //default
  }

  public get disableDefaultUI(): boolean {
    if (this.ngControl.disabled) {
      return true;
    }

    return false; //default
  }

  public get showAddressTextSearch(): boolean {
    if (this.ngControl.disabled) {
      return false;
    }

    return true; //default
  }

  public get isShowHelpText(): boolean {
    if (this.ngControl.disabled) {
      return false;
    }

    return true; //default
  }

  public get valueLatitude(): string {
    return this.value && this.value.latitude
      ? this.value.latitude
      : this.notSelectedLocationText;
  }

  public get valueLongitude(): string {
    return this.value && this.value.longitude
      ? this.value.longitude
      : this.notSelectedLocationText;
  }

  constructor(
    translate: TranslateService,
    injector: Injector,
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    super(translate, injector);

    ///this will help loading the default location on the map
    this.value = this.createDefaultLocation();

    this.zoom = environment.googleMapConfigs.defaultZoom;
    this.radius = environment.googleMapConfigs.defaultRadius;
    this.circleColor = environment.googleMapConfigs.defaultCircleColor;

    this.loadGoogleApis();
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }

    this.ngControl = this.inj.get(NgControl);

    this.loadCurrentLocation();
  }

  ngAfterViewInit() {
    this.subscribeToAutoCompleteChange();
  }

  // ///used for reactive forms
  // public override setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);

  //   if (isDisabled == false) {
  //     ///#search get remove from dom on disabled map, hence re-register the events
  //     this.subscribeToAutoCompleteChange();
  //   }
  // }

  private async loadGoogleApis() {
    await this.mapsApiLoader.load();
    this.geoCoder = new google.maps.Geocoder();
  }

  private loadCurrentLocation(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentLatitude = position.coords.latitude;
            this.currentLongitude = position.coords.longitude;

            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(
          'geolocation is not exist, enable the browser navigation service'
        );
      }
    });
  }

  private async subscribeToAutoCompleteChange() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef?.nativeElement
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.setLocation(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
      });
    });
  }

  private getLoadedCurrentLocation(): MapLocation {
    if (this.currentLongitude && this.currentLatitude) {
      return {
        longitude: this.currentLongitude,
        latitude: this.currentLatitude,
        address: '',
      };
    } else {
      return null;
    }
  }

  private async setMapToCurrentLocation() {
    await this.loadCurrentLocation();

    this.longitude = this.currentLongitude;
    this.latitude = this.currentLatitude;
  }

  public async markerDragEnd($event: any) {
    await this.setLocation($event.coords.lat, $event.coords.lng);
  }

  private async setLocation(latitude: number, longitude: number) {
    const address = await this.getAddress(this.latitude, this.longitude);

    this.value = {
      latitude: latitude,
      longitude: longitude,
      address: address,
    };
  }

  private async getAddress(
    latitude: number,
    longitude: number
  ): Promise<string> {
    if (this.geoCoder) {
      await this.geoCoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (results: any, status: any) => {
          if (status === 'OK') {
            if (results[0]) {
              return results[0].formatted_address;
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }
      );
    }

    return null;
  }

  public get touched() {
    return this.ngControl.control.touched;
  }

  public addressTextChange() {
    if (this.address == '') {
      this.value = null;
    }
  }

  private createDefaultLocation(): MapLocation {
    const location = new MapLocation();
    location.latitude = environment.googleMapConfigs.defaultLatitude;
    location.longitude = environment.googleMapConfigs.defaultLongitude;

    return location;
  }
}
