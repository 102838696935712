<div class="form-group" #map>
  <label [innerText]="(key | translate).labelText"></label>
  <div class="detail-text">{{ this._location.address }}</div>
  <div *ngIf="_location">
    <agm-map
      [style.width.px]="map.offsetWidth"
      [zoom]="zoom"
      [latitude]="_location.latitude"
      [longitude]="_location.longitude"
    >
      <agm-marker
        [latitude]="_location.latitude"
        [longitude]="_location.longitude"
        [markerDraggable]="false"
      >
      </agm-marker>

      <agm-circle
        [latitude]="_location.latitude"
        [longitude]="_location.longitude"
        [radius]="radius"
        [editable]="false"
        [fillColor]="circleColor"
      >
      </agm-circle>
    </agm-map>
  </div>
</div>
