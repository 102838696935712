import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { CitesWorkflowMain } from './CitesWorkflowMain';
import { ReviewFlowStepBaseComponent } from 'src/app/shared/base/ReviewFlowStepBaseComponent';
import { FormGroup } from '@angular/forms';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { FormSectionOptions } from 'src/app/services/es-shared/es-form-section-directive/FormSectionOptions';
import { CitesBaseServiceRequest } from 'src/app/core/models/cites/shared/CitesBaseServiceRequest';

export abstract class CitesReviewFlowStepBaseComponent<
  TWorkflow extends CitesWorkflowMain
> extends ReviewFlowStepBaseComponent {
  config$: Observable<EsServiceRequestConfiguration>;
  transaction$: Observable<CitesBaseServiceRequest<any>>;

  initiatorActionForm: FormGroup;

  requestDetailsFormSection: FormSectionOptions = {
    isCollapsible: true,
    render: true,
  };

  constructor(
    public workflowService: TWorkflow,
    protected router: Router,
    translate: TranslateService
  ) {
    super(translate);

    this.workflowService.setCurrentStep(WorkflowSteps.ReviewConfiguration);

    this.config$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.initiatorActionForm =
      this.workflowService.globalObject.ServiceDetailsConfiguration.initiatorActionForm;

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;
  }
}
